import { css } from '@emotion/core';

import { SPACER } from 'consts';
import CustomTheme from 'interfaces/CustomTheme';

export const PRIMARY_NAV_HEIGHT = SPACER.x3large;
export const TICKER_HEIGHT = SPACER.xlarge;

export const Z_INDEX = {
  EXHIBITION_BASE: 0, 
  EXHIBITION_LABELS: 1, 
  EXHIBIT_BASE: 10,
  PRIMARY_NAV: 100, 
};

export const indexLayoutStyle = css`
  max-width: 1280px;
  padding: ${SPACER.x5large} ${SPACER.base};
  margin: 0 auto;
  border-left: 1px dashed;
  border-right: 1px dashed;
  min-height: calc(100vh - ${PRIMARY_NAV_HEIGHT});
  overflow-wrap: anywhere;

  display: flex; 
  flex-direction: column;
`;

export const indexLinkStyle = css`
  display: inline-flex; 
  flex-direction: column;

  text-decoration: none; 

  &:hover {
    text-decoration: underline dashed;
	  text-decoration-skip-ink: none;
	  text-decoration-thickness: 1px;
  }
`;

export const indexArticleStyle = css`
  margin: ${SPACER.xlarge} 0;
`;

export const indexTitleStyle = (theme: CustomTheme) => css`
  font-size: 24px;
  display: inline;
  font-family: ${theme.fonts.serif};
`;

export const indexCaptionStyle = (theme: CustomTheme) => css`
  font-family: ${theme.fonts.monospace};
  margin-top: ${SPACER.xsmall};
  display: inline-block;
  font-size: 14px;
  line-height: 1.125;
`;
import React from 'react';

import * as Styled from './style';

const ErrorFallback = ({ error }: { error: Error }) => (
  <Styled.ErrorFallback>
    Error :( ... {error.message}
  </Styled.ErrorFallback>
);

export default ErrorFallback;
import React from 'react';

import * as Styled from './style';
import { 
  MIN_BOTTLE_COUNT_PER_AXIS, 
  MAX_BOTTLE_COUNT_PER_AXIS,
  BottlesCountType, 
  BottleAxisType,
} from '../..';

const SettingsPanel = ({
  bottlesCount,
  handleBottlesCountChange,
  maxBottleRotationalForce,
  handleMaxBottleRotationalForceChange,
  scrollDownTo,
} : {
  bottlesCount: BottlesCountType;
  handleBottlesCountChange: ({ count, axis } : { count: number; axis: BottleAxisType; }) => void;
  maxBottleRotationalForce: number;
  handleMaxBottleRotationalForceChange: ({ value } : { value: string }) => void;
  scrollDownTo: string;
}) => (
  <Styled.SettingsPanel>
    <BottleCountStepper 
      axis="x" 
      bottlesCount={bottlesCount} 
      handleBottlesCountChange={handleBottlesCountChange} 
    />
    <BottleCountStepper 
      axis="y" 
      bottlesCount={bottlesCount} 
      handleBottlesCountChange={handleBottlesCountChange} 
    />
    <BottleCountStepper 
      axis="z" 
      bottlesCount={bottlesCount} 
      handleBottlesCountChange={handleBottlesCountChange} 
    />

    <Styled.Slider>
      <Styled.SliderLabel>
        <div>wiggle</div>
        <div>{maxBottleRotationalForce}</div>
      </Styled.SliderLabel>

      <input 
        type="range" 
        id="maxBottleRotationalForce" 
        name="maxBottleRotationalForce"
        min="0" 
        max="1"
        value={maxBottleRotationalForce}
        onChange={({ target: { value } }) => handleMaxBottleRotationalForceChange({ value })}
        step="0.01"
      />
    </Styled.Slider>

    <Styled.ScrollDownLink to={scrollDownTo} title="Scroll down ...">
      ↓ Scroll ↓
    </Styled.ScrollDownLink>

  </Styled.SettingsPanel>
);

const BottleCountStepper = ({ 
  bottlesCount, 
  handleBottlesCountChange, 
  axis, 
}: {
  bottlesCount: BottlesCountType;
  handleBottlesCountChange: ({ count, axis } : { count: number; axis: BottleAxisType; }) => void;
  axis: BottleAxisType;
}) => (
  <Styled.Stepper>
    {`${axis}: `}
    <Styled.Control>
      <Styled.StepperButton 
        disabled={bottlesCount[axis] <= MIN_BOTTLE_COUNT_PER_AXIS}
        onClick={() => { 
          handleBottlesCountChange({ count: bottlesCount[axis] - 1, axis });
        }}
      >
        {bottlesCount[axis] <= MIN_BOTTLE_COUNT_PER_AXIS ? <span role="img" aria-label="not-allowed">🚫</span> : '-'}
      </Styled.StepperButton>
      <span> {`${bottlesCount[axis]}`.padStart(2, '0')} </span>
      <Styled.StepperButton 
        disabled={bottlesCount[axis] >= MAX_BOTTLE_COUNT_PER_AXIS}
        onClick={() => { 
          handleBottlesCountChange({ count: bottlesCount[axis] + 1, axis });
        }}
      >
        {bottlesCount[axis] >= MAX_BOTTLE_COUNT_PER_AXIS ? <span role="img" aria-label="not-allowed">🚫</span> : '+'}
      </Styled.StepperButton>
    </Styled.Control>
  </Styled.Stepper> 
);

export default SettingsPanel;
import styled from '@emotion/styled';

import CustomTheme from 'interfaces/CustomTheme';

export const Loading = styled.div<{ theme: CustomTheme; }>`
  position: relative; 
  height: 100%; 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  font-size: 32px; 

  font-family: monospace;
`;
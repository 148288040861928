// @ts-ignore
import prism from '@theme-ui/prism/presets/theme-ui';

const inlineCodeStyles = {
  fontFamily: 'Menlo, monospace',
  fontSize: '16px',
  backgroundColor: 'muted',
  color: 'secondary',
  lineHeight: 1.6,
};

export default {
  useColorSchemeMediaQuery: true,
  
  colors: {    
    text: '#040d14',
    background: 'hsl(10, 10%, 98%)',
    highlight: 'hsl(10, 40%, 90%)',
    gray: 'hsl(10, 20%, 50%)',

    modes: {
      times: {
        text: '#33302e',
        background: '#fff1e6',
        highlight: '#efeffe',
        gray: '#777777',

        primary: '#0D7680',
        secondary: '#0F5499',
        muted: '#f3eeed',
        accent: '#990F3D',
      },

      light: {
        text: '#040d14',
        background: 'hsl(10, 10%, 98%)',
        highlight: '#efeffe',
        gray: 'hsl(10, 20%, 50%)',

        primary: '#07c',
        secondary: '#30c',
        muted: '#f2f2f2',
        accent: '#990F3D',
      },
      dark: {
        text: '#ffffff', 
        background: '#0A0A0A',
        highlight: '#29112c', // something bright 

        primary: '#3cf',
        secondary: '#e0f',
        muted: '#191919',
        gray: '#999',
        accent: '#c0f',
      },
    },
  },

  buttons: {
    primary: {
      color: 'background',
      bg: 'text',
    }, 

    secondary: {
      color: 'text',
      bg: 'background',
    },
  },

  fonts: {
    sansSerif: 'ui-sans-serif, sans-serif',
    serif: 'Georgia, ui-serif, serif',
    monospace: 'ui-monospace, monospace',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontWeights: {
    light: 300, 
    regular: 400, 
    bold: 700,
  },

  space: [0, 2, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512],

  styles: {
    root: {
      transitionProperty: 'background-color',
      transitionTimingFunction: 'ease-out',
      transitionDuration: '.25s',
    },
    h1: {
      fontSize: 32,
      lineHeight: 1.125,
      fontFamily: 'serif',
      mt: 5, 
      mb: 3, 
    },
    h2: {
      fontSize: 24,
      lineHeight: 1.125,
      fontWeight: 500,
      mt: 4, 
      mb: 3, 
    },
    p: {
      fontSize: 18,
      fontFamily: 'serif',
      lineHeight: 1.6,
      mb: 5,
      maxWidth: 624,

      code: {
        ...inlineCodeStyles,
      },
      sup: {
        fontSize: '75%',
        position: 'relative',
        verticalAlign: 'baseline',
        top: '-0.5em',
        lineHeight: 0,
      },
    },
    ul: {
      fontFamily: 'serif',
      fontSize: 18, 
      lineHeight: 1.6, 
      mb: 5, 
      maxWidth: 624,
      listStyle: 'disc',
      ml: 6,

      code: {
        ...inlineCodeStyles,
      },
    },
    img: {
      border: '1px solid',
      mb: 5,
    },
    em: {
      fontStyle: 'italic',
    },
    strong: {
      fontWeight: 'bold',
    },
    pre: {
      ...prism,
      fontFamily: 'Menlo, monospace',
      lineHeight: 1.5, 
      fontSize: '14px',
      fontWeight: 400,
      padding: '1rem',
      border: '1px solid',
      maxWidth: '760px',
      backgroundColor: 'muted',
      overflowY: 'scroll',
      my: 6,
      transition: 'background-color .25s ease-out',

      '.highlight': {
        backgroundColor: 'highlight',
        fontWeight: 'bold',
      },
    },

    blockquote: {
      borderLeft: '4px solid',
      pl: 4,
      fontStyle: 'italic',
    },
  },
};
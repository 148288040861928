import styled from '@emotion/styled';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import { SPACER } from 'consts';
import * as Shared from 'components/style';
import CustomTheme from 'interfaces/CustomTheme';

export const SettingsPanel = styled.div<{ theme: CustomTheme; }>`
  position: absolute; 
  z-index: ${Shared.Z_INDEX.EXHIBIT_BASE};
  top: 0; 
  left: 0;
  padding: ${SPACER.base};
  line-height: 1.125;

  font-weight: bold;
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.monospace};
`;

export const StepperButton = styled.button<{ theme: CustomTheme; }>`
  border: 0;
  cursor: pointer;
  font-size: 14px; 
  font-weight: bold; 
  height: ${SPACER.xlarge};
  width: ${SPACER.xlarge};
  line-height: 1.125;

  ${({ theme }) => `
    font-family: ${theme.fonts.monospace};
    background-color: ${theme.colors.background};
    transition: background-color .25s ease-out;
    color: ${theme.colors.text};
  `}
`;

export const Control = styled.div<{ theme: CustomTheme; }>`
  border: 1px solid;

  ${({ theme }) => `
  background-color: ${theme.colors.background};
  transition: background-color .25s ease-out;
  color: ${theme.colors.text};
`}
`;

export const Stepper = styled.div`
  display: flex;
  align-items: center; 
  justify-content: space-between;
  margin-bottom: ${SPACER.small};
`;

export const Slider = styled.div`
  border: 1px transparent; 
  padding: ${SPACER.x2small} 0;

  > input { 
    width: 100%;
    margin: 0;
  }
`;

export const SliderLabel = styled.div<{ theme: CustomTheme; }>`
  display: flex; 
  justify-content: space-between;
  align-items: center;
  margin: ${SPACER.xsmall} 0;

  ${({ theme }) => `
    background-color: ${theme.colors.background};
    transition: background-color .25s ease-out;
    color: ${theme.colors.text};
  `}
`;

export const ScrollDownLink = styled(AnchorLink)<{ theme: CustomTheme; }>`
  display: block;
  text-align: center;

  ${({ theme }) => `
    background-color: ${theme.colors.background};
    transition: background-color .25s ease-out;
    color: ${theme.colors.text};
  `}
`;
import styled from '@emotion/styled';

import { SPACER } from 'consts';

export const ErrorFallback = styled.div`
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100%;

  padding: ${SPACER.base};
`;
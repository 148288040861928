import React, { Suspense, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Html } from '@react-three/drei';
import { v4 as uuid } from 'uuid';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from 'components/errorFallback';

import Bottle from './components/bottle';
import CameraControls from './components/cameraControls';
import Loading from './components/loading';
import SettingsPanel from './components/settingsPanel';

const BOTTLE_OFFSET = 6;
export const MIN_BOTTLE_COUNT_PER_AXIS = 1;
export const MAX_BOTTLE_COUNT_PER_AXIS = 10;

export type BottlesCountType = {
  x: number; 
  y: number; 
  z: number;
}

export type BottleAxisType = 'x' | 'y' | 'z';

// TODO: Can optimize by not looping over object creation in render function.
const Bottles = ({ 
  scrollDownTo='/#writing',
} : { 
  scrollDownTo?: string; 
}) => {
  const [bottlesCount, setBottlesCount] = useState<BottlesCountType>({
    x: 2, 
    y: 1, 
    z: 1,
  });
  const [maxBottleRotationalForce, setMaxBottleRotationalForce] = useState(0.01);

  const handleBottlesCountChange = ({ 
    count, 
    axis, 
  } : { 
    count: number; 
    axis: BottleAxisType;
  }) => {
    setBottlesCount(prevState => ({...prevState, [axis]: count }));
  };

  const handleMaxBottleRotationalForceChange = ({ value }: { value: string }) => {
    setMaxBottleRotationalForce(parseFloat(value));
  };

  return(
    <ErrorBoundary
      fallbackRender={({ error }) => <ErrorFallback error={error} />}
    >
      <SettingsPanel 
        bottlesCount={bottlesCount}
        handleBottlesCountChange={handleBottlesCountChange}
        maxBottleRotationalForce={maxBottleRotationalForce}
        handleMaxBottleRotationalForceChange={handleMaxBottleRotationalForceChange}
        scrollDownTo={scrollDownTo}
      />
      <Canvas>
        <CameraControls />
        <ambientLight intensity={0.5} />
        <directionalLight />
        <Suspense fallback={(
          <Html fullscreen>
            <Loading />
          </Html>
        )}>
          {Array.from({ length: bottlesCount.x }).map((_, xdx) => (
            Array.from({ length: bottlesCount.y }).map((_, ydx) => (
              Array.from({ length: bottlesCount.z }).map((_, zdx) => (
                <Bottle 
                  key={uuid()} 
                  position={[
                    xdx * BOTTLE_OFFSET, 
                    ydx * BOTTLE_OFFSET, 
                    zdx * BOTTLE_OFFSET,
                  ]}
                  rotationalForces={{
                    x: getRandomValue(0, maxBottleRotationalForce),
                    y: getRandomValue(0, maxBottleRotationalForce),
                    z: getRandomValue(0, maxBottleRotationalForce),
                  }}
                />
              ))
            ))
          ))}
        </Suspense>
      </Canvas>
    </ErrorBoundary>
  );
};

const getRandomValue = (min: number, max: number) => Math.random() * (max - min) + min;

export default {
  Component: Bottles, 
  title: 'Bottles',
  createdAt: '2020-12-25',
  technique: 'Custom textures, <a href="/blog/vanilla-three-js-to-react-three-fiber/">react-three-fiber</a>.',
};
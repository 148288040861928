import React, { useRef } from 'react';
import { MeshProps, useLoader, useFrame } from '@react-three/fiber';
import { Mesh, TextureLoader, sRGBEncoding } from 'three';

import SuperbottleTop from './textures/superbottle-top.png';
import SuperbottleBody from './textures/superbottle-body.png';
import SuperbottleBottom from './textures/superbottle-bottom.png';

const Bottle = (props: MeshProps & { rotationalForces: { x: number; y: number; z: number; } }) => {
  const { rotationalForces: { x, y, z } } = props;

  const bottle = useRef<Mesh>();
  const { topTexture, bodyTexture, bottomTexture } = useBottleTextures();

  useFrame(() => {
    if (!bottle || !bottle.current) {
      return;
    }

    bottle.current.rotation.x += x; 
    bottle.current.rotation.y += y;
    bottle.current.rotation.z += z;
  });

  return (
    <mesh
      {...props}
      ref={bottle}
    >
      <cylinderBufferGeometry attach="geometry" args={[0.5, 0.5, 3.5, 48]} />
      <meshStandardMaterial map={bodyTexture} attachArray="material" />
      <meshStandardMaterial map={topTexture} attachArray="material" />
      <meshStandardMaterial map={bottomTexture} attachArray="material" />
    </mesh>
  );
};

const useBottleTextures = () => {
  const textures = {
    topTexture: useLoader(TextureLoader, SuperbottleTop),
    bodyTexture: useLoader(TextureLoader, SuperbottleBody),
    bottomTexture: useLoader(TextureLoader, SuperbottleBottom),
  };

  Object.values(textures).forEach(texture => {
    texture.encoding = sRGBEncoding;
    texture.anisotropy = 16;
  });

  return textures;
};

export default Bottle; 